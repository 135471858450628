var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Berita Acara Detail Tukar Unit" } },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label: "Internal Contract Number:"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataForm.internalContractNumber
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Replacement Date:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.replacementDate) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Replacement Number:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataForm.replacementNumber
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Name:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.customerName) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("a-table", {
                                attrs: {
                                  columns: _vm.columnsTable,
                                  "data-source": _vm.dataSource,
                                  pagination: false,
                                  paginationcustom: true,
                                  scroll: { x: "calc(700px + 50%)", y: 400 }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c("Pagination", {
                                attrs: {
                                  total: _vm.totalData,
                                  pageSizeSet: _vm.limit,
                                  idPagination: "pagination1"
                                },
                                on: {
                                  "response-pagesize-change":
                                    _vm.responsePageSizeChange,
                                  "response-currentpage-change":
                                    _vm.responseCurrentPageChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12, align: "end" } },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      ":" +
                                      _vm._s(_vm.totalData) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { "margin-top": "1rem" },
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.claim.label)
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.claim.decorator,
                                            expression:
                                              "formRules.claim.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.claim.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.claim.placeholder
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { "margin-top": "1rem" },
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.replacementFee.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.replacementFee
                                                .decorator,
                                            expression:
                                              "formRules.replacementFee.decorator"
                                          }
                                        ],
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          name:
                                            _vm.formRules.replacementFee.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.replacementFee
                                              .placeholder
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.onBlur(
                                              $event,
                                              "replacementFee"
                                            )
                                          },
                                          focus: function($event) {
                                            return _vm.onFocus(
                                              $event,
                                              "replacementFee"
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { "margin-top": "1rem" },
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.notes.label)
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.notes.decorator,
                                            expression:
                                              "formRules.notes.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.notes.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.notes.placeholder
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24, align: "end" } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: { click: _vm.handleReset }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "vertical-align": "0" },
                                        attrs: { type: "close" }
                                      }),
                                      _vm._v("Reset ")
                                    ],
                                    1
                                  ),
                                  _vm.$can("update", "unit-exchange")
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loadingSave
                                          },
                                          on: { click: _vm.handleSave }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "vertical-align": "0"
                                            },
                                            attrs: { type: "save" }
                                          }),
                                          _vm._v("Save ")
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loadingPrint
                                      },
                                      on: { click: _vm.handlePrint }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "vertical-align": "0" },
                                        attrs: { type: "printer" }
                                      }),
                                      _vm._v("Print ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }