
























































































































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { RequestUpdateBastExchange } from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import printJS from "print-js";
import Vue from "vue";

interface DataForm {
  internalContractNumber: string;
  replacementDate: string;
  replacementNumber: string;
  customerName: string;
  supplierExpeditionCode: string;
  supplierExpeditionName: string;
  supplierExpeditionAddress: string;
  truk: string;
  description: string;
  transactionNumber: string;
  contractPeriod: string;
  customerShipTo: string;
  customerBillTo: string;
  phoneNumber: string;
  customerPicName: string;
}

interface CustomDataSource {
  key: number;
  no: string;
  status: string;
  unitCode: string;
  merk: string;
  type: string;
  serialNumber: string;
  model: string;
  specification: string;
  capacity: string;
}

export default Vue.extend({
  data() {
    return {
      id: "" as string,
      form: this.$form.createForm(this, { name: "viewPrintBAST" }),
      dataSource: [] as CustomDataSource[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalData: 0 as number,
      formatCurrency: currencyFormat,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      loadingPrint: false as boolean,
      loadingSave: false as boolean,
      selectedRowKeys: [] as number[],
      dataForm: {
        internalContractNumber: "",
        replacementDate: "",
        replacementNumber: "",
        customerName: "",
      } as DataForm,
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
          customRender: (value, _row, index) => {
            const obj = {
              children: value,
              attrs: {} as { rowSpan: number; colSpan: number },
            };
            if (index % 2 === 0) {
              obj.attrs.rowSpan = 2;
            }
            // These two are merged into above cell
            if (index % 2 === 1) {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: "Merk",
          dataIndex: "merk",
          key: "merk",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: "Model",
          dataIndex: "model",
          key: "model",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: "Specification",
          dataIndex: "specification",
          key: "specification",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
        {
          title: "Capacity",
          dataIndex: "capacity",
          key: "capacity",
          width: 150,
          // scopedSlots: { customRender: 'isNull' },
        },
      ],
      formRules: {
        claim: {
          label: "Claim",
          name: "claim",
          placeholder: "Insert your Claim",
          decorator: [
            "claim",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        replacementFee: {
          label: "Replacement Fee",
          name: "replacementFee",
          placeholder: "Insert your Replacement Fee",
          decorator: [
            "replacementFee",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        notes: {
          label: "Notes",
          name: "notes",
          placeholder: "Insert your Notes",
          decorator: [
            "notes",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetailBastExchange();
  },
  methods: {
    onBlur(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: string };
        dataobj[form] = this.formatCurrency(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: number };
        dataobj[form] = this.formatCurrencytoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    getDetailBastExchange() {
      const params: RequestQueryParamsModel = {};
      salesOrderServices
        .getDetailBastExchange(params, this.id)
        .then(response => {
          this.dataForm.internalContractNumber = response.icNumber;
          this.dataForm.replacementDate = response.replacementDate;
          this.dataForm.replacementNumber = response.replacementNumber;
          this.dataForm.customerName = response.customerName;
          this.dataSource = [];
          this.totalData = response.icChangeUnitDTOS.length;
          response.icChangeUnitDTOS.forEach((dataForeach, index) => {
            this.dataSource.push({
              no: `${index + 1}.`,
              key: (index + 1) * 2,
              status: "FROM",
              unitCode: dataForeach.unitCodeFrom,
              merk: dataForeach.merkFrom,
              type: dataForeach.typeFrom,
              serialNumber: dataForeach.snFrom,
              model: dataForeach.modelFrom,
              specification: dataForeach.specificationFrom,
              capacity: dataForeach.capacityFrom,
            });
            this.dataSource.push({
              no: `${index + 1}.`,
              key: (index + 1) * 2 + 1,
              status: "TO",
              unitCode: dataForeach.unitCodeTo,
              merk: dataForeach.merkTo,
              type: dataForeach.typeTo,
              serialNumber: dataForeach.snTo,
              model: dataForeach.modelTo,
              specification: dataForeach.specificationTo,
              capacity: dataForeach.capacityTo,
            });
          });
          // this.dataSource = response.icChangeUnitDTOS.map((dataMap, index) => {
          //   return { ...dataMap,
          //     key: index,
          //   }
          // })
          this.form.setFieldsValue({
            claim: response.claim,
            replacementFee: this.formatCurrency(response.replacementFee),
            notes: response.notes,
          });
        });
    },
    onSelectChange(selectedRowKeys): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleReset() {
      this.form.resetFields();
    },
    handleSave() {
      this.form.validateFields((err, res) => {
        if (err) return;
        const payload: RequestUpdateBastExchange = {
          claim: res.claim,
          replacementFee: this.formatCurrencytoNumber(res.replacementFee),
          notes: res.notes,
        };
        this.loadingSave = true;
        salesOrderServices
          .updateBastExchange(payload, this.id)
          .then(() => {
            this.$notification.success({
              message: "Success",
              description: Messages.UPDATE_SUCCESS,
              duration: 30,
            });
            this.getDetailBastExchange();
          })
          .finally(() => (this.loadingSave = false));
      });
    },
    handlePrint() {
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
      };
      this.loadingPrint = true;
      salesOrderServices
        .getPrintChangeUnit(params, this.id)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            printJS(url);
          }
        })
        .finally(() => (this.loadingPrint = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      // this.onSubmit()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      // this.onSubmit()
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      };
    },
  },
});
